var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view view-cart view--mt-0" },
    [
      _vm.productPage
        ? [
            !_vm.order
              ? _c(
                  "section",
                  {
                    staticClass:
                      "section section--fullScreen section--flexCenter",
                  },
                  [
                    _vm.isLoading
                      ? _c(
                          "div",
                          { staticClass: "container container--xs" },
                          [
                            _c("VLoadSpinner", {
                              attrs: { "on-page": "order-review" },
                            }),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "container container--xs" }, [
                          _c("h2", { staticClass: "textIsError" }, [
                            _vm._v("Error!"),
                          ]),
                          _c("h4", [
                            _vm._v(
                              "We don't have any records for Order ID: " +
                                _vm._s(_vm.orderId)
                            ),
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn--white",
                              attrs: { href: "mailto:" + _vm.producer.email },
                            },
                            [_vm._v("Hit Up Support")]
                          ),
                        ]),
                  ]
                )
              : _c(
                  "section",
                  {
                    staticClass:
                      "section section--fullScreen section--flexCenter",
                  },
                  [
                    _c("div", { staticClass: "container container--xs" }, [
                      _c("h2", { staticClass: "h2" }, [
                        _vm._v("Successful Purchase"),
                      ]),
                      _c("p", { staticClass: "p p--grey" }, [
                        _vm._v(
                          "Thanks for your purchase, download your files here."
                        ),
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn--lg btn--primary",
                          attrs: {
                            href:
                              "" +
                              _vm.BASE_URL +
                              _vm.order.bought_products[0].file_url,
                            target: "_blank",
                          },
                        },
                        [_vm._v("DOWNLOAD")]
                      ),
                    ]),
                  ]
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }